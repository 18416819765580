<template>
  <a-spin :tip="loadingTip" :spinning="loading">
    <div>
      <a-form-model
        ref="liveInfoForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="推流地址" prop="name"
          ><span>{{ liveInfo.pushUrl }}</span>
        </a-form-model-item>
        <a-form-model-item label="OBS推流地址" prop="name"
          ><span>{{ liveInfo.pushUrl.substring(0, 25) }}</span>
        </a-form-model-item>
        <a-form-model-item label="OBS推流名称" prop="name"
          ><span>{{
            liveInfo.pushUrl.substring(25, liveInfo.pushUrl.length)
          }}</span>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 5 }">
          <!-- <pxkeji-btn name="保存" type="primary" @click="handleSubmit" /> -->
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
var that;
export default {
  props: {
    id: String,
  },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      liveInfo: {},
      rules: {},
      loading: false,
      loadingTip: "加载中...",
    };
  },
  mounted() {
    that = this;
    if (that.id != 0) {
      that.getInfo();
    }
  },
  methods: {
    getInfo() {
      that.loading = true;
      that.$api.course
        .getLiveInfo(that.id)
        .then((res) => {
          if (res.errorCode == that.$msg.responseCode.success) {
            that.liveInfo = res.data;
          } else {
            that.$message.error(res.errorMsg);
          }
          that.loading = false;
        })
        .catch(() => {
          that.loading = false;
        });
    },

    cancelClick() {
      that.$emit("callbackMethod");
    },
  },
};
</script>
<style>
</style>