<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="课程名称">
              <a-input placeholder="请输入" v-model="search.courseName" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="课程状态">
              <a-select v-model="search.status">
                <a-select-option value="">全选</a-select-option>
                <a-select-option value="0">未发布</a-select-option>
                <a-select-option value="1">未开始</a-select-option>
                <a-select-option value="2">直播中</a-select-option>
                <a-select-option value="3">已结束</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6" :style="{ textAlign: 'left' }">
            <a-button type="primary" @click="searchList">搜索</a-button>
          </a-col>
        </a-row>
      </a-form>
      <div>
        <pxkeji-btn
          name="添加"
          :roles="['课程-课程添加']"
          class="pxkeji_basic_btn"
          @click="showInfo('0')"
          type="primary"
        />
        <pxkeji-btn
          name="发布"
          :disabled="selectRows.length == 0"
          @click="batchPublish(1)"
          :roles="['课程-课程发布']"
          class="pxkeji_basic_btn"
        />
        <pxkeji-btn
          name="下架"
          :disabled="selectRows.length == 0"
          @click="batchPublish(0)"
          :roles="['课程-课程发布']"
          class="pxkeji_basic_btn"
        />
      </div>
    </div>

    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :rowSelection="{
          type: 'checkbox',
          columnWidth: 40,
          onChange: onRowChange,
          selectedRowKeys: selectRows,
        }"
        :customRow="customRow"
        :rowClassName="setRowClassName"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <span slot="status" slot-scope="status">
          <a-tag v-if="status == 1" color="blue">未开始</a-tag>
          <a-tag v-else-if="status == 2" color="red">直播中</a-tag>
          <a-tag v-else-if="status == 3" color="green">已结束</a-tag>
          <a-tag v-else color="gray">未发布</a-tag>
        </span>
        <span slot="recommend" slot-scope="recommend">
          <a-switch
            checkedChildren="开"
            unCheckedChildren="关"
            :loading="switchLoading"
            v-model="recommend.recommend"
            @change="switchDisplay(recommend)"
          />
        </span>

        <span slot="action" slot-scope="action">
          <pxkeji-btn
            name="编辑"
            @click="showInfo(action.id)"
            size="small"
            type="default"
            :roles="['课程-课程修改']"
          />
          <pxkeji-btn
            name="公告"
            @click="showNotice(action.id)"
            size="small"
            type="default"
            :roles="[]"
          />
          <a-popconfirm
            title="是否确认删除此课程"
            @confirm="delInfo(action.id)"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="删除"
              size="small"
              type="danger"
              :loading="isloading"
              :roles="['课程-课程删除']"
            />
          </a-popconfirm>
          <pxkeji-btn
            v-if="action.liveType == 'tencentCloud' && action.status == 2"
            name="直播详情"
            @click="liveDetail(action.id)"
            size="small"
            type="default"
            class="pxkeji_dro_btn"
          />
          <a-popconfirm
            title="是否确认开始直播"
            @confirm="startLive(action.id)"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              v-if="action.liveType == 'tencentCloud' && action.status == 1"
              name="开始直播"
              size="small"
              type="default"
              class="pxkeji_dro_btn"
            />
          </a-popconfirm>
          <a-popconfirm title="是否确认结束直播" @confirm="endLive(action.id)">
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              v-if="action.liveType == 'tencentCloud' && action.status == 2"
              name="结束直播"
              size="small"
              type="danger"
              class="pxkeji_dro_btn"
            />
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <a-modal
      :title="info.title"
      v-model="info.visible"
      width="1000px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
      :afterClose="modalClose"
    >
      <LiveCourseInfo @callbackMethod="save" :id="info.id" />
    </a-modal>
    <a-modal
      title="直播信息"
      v-model="liveInfo.visible"
      width="60%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <LiveInfo @callbackMethod="saveLiveInfo" :id="liveInfo.id" />
    </a-modal>
    <a-modal
      title="直播公告"
      v-model="noticeInfo.visible"
      width="60%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <LiveNoticeList @callbackMethod="saveNoticeInfo" :id="noticeInfo.id" />
    </a-modal>
  </div>
</template>
<script>
import LiveCourseInfo from "./LiveCourseInfo.vue"; //编辑
import LiveInfo from "./liveInfo.vue"; //直播信息
import LiveNoticeList from "./LiveNoticeList.vue"; //直播公告
const columns = [
  {
    dataIndex: "name",
    key: "name",
    align: "left",
    title: "课程名称",
  },
  // {
  //   dataIndex: "startTime",
  //   key: "startTime",
  //   align: "center",
  //   width: 110,
  //   ellipsis: true,
  //   title: "开始时间",
  // },
  // {
  //   dataIndex: "endTime",
  //   key: "endTime",
  //   align: "center",
  //   width: 110,
  //   ellipsis: true,
  //   title: "结束时间",
  // },
  {
    dataIndex: "status",
    key: "status",
    align: "center",
    title: "状态",
    width: 140,
    scopedSlots: { customRender: "status" },
  },
  {
    key: "recommend",
    align: "center",
    title: "推荐",
    width: 140,
    scopedSlots: { customRender: "recommend" },
  },
  {
    dataIndex: "createTime",
    key: "createTime",
    align: "center",
    width: 160,
    ellipsis: true,
    title: "发布日期",
  },
  {
    title: "操作",
    key: "action",
    width: 300,
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

let _this;
export default {
  name: "LiveCourseList",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 293,
      },
      search: {
        //搜索条件
        status: "",
        courseName: "",
      },
      selectRows: [], //选中项编号
      selectRowId: "",
      isloading: false,
      info: {
        title: "",
        visible: false,
        id: 0,
      },
      liveInfo: {
        visible: false,
        id: 0,
      },
      noticeInfo: {
        visible: false,
        id: 0,
      },
      switchLoading: false, //开关等待
    };
  },
  components: {
    LiveCourseInfo,
    LiveInfo,
    LiveNoticeList,
  },
  mounted: function () {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 293;
      _this.innerWidth = window.innerWidth;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.GetList();
  },
  methods: {
    switchDisplay(e) {
      _this.switchLoading = true;
      _this.$api.course
        .updateRecommendById(e.id, _this.$commen.changeBooleanInt(e.recommend))
        .then((res) => {
          _this.switchLoading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.clearRows();
      _this.$api.course
        .getPageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          1,
          null,
          _this.search.courseName,
          _this.search.status
        )
        .then((res) => {
          if (res.errorCode == this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
            res.data.records.forEach((value, index) => {
              res.data.records[index].recommend = this.$commen.changeIntBoolean(
                value.recommend
              );
            });
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    onRowChange(selectedRowKeys) {
      //table 多选
      _this.selectRows = selectedRowKeys;
    },
    clearRows() {
      //清空选中项
      _this.selectRows = [];
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    showInfo(id) {
      _this.info.title = id == "0" ? "添加" : "编辑";
      _this.info.id = id + "";
      _this.info.visible = true;
    },
    showNotice(id) {
      _this.noticeInfo.id = id;
      _this.noticeInfo.visible = true;
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            _this.selectRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record) {
      return _this.selectRowId == record.id ? "selectRow" : "";
    },
    modalClose() {
      setTimeout(() => {
        _this.selectRowId = "";
      }, 3000);
    },
    //发布
    batchPublish(status) {
      let msg = status == 1 ? "发布" : "下架";
      _this.$confirm({
        title: "提示",
        content: `您确定要${msg}已选择的课程吗？`,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.isDisable(_this.selectRows.join(","), status);
        },
        onCancel() {},
      });
    },
    /**禁用启用的方法 */
    isDisable(ids, status) {
      _this.isloading = true;
      _this.$api.course
        .updateStatusByIds(ids, status)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.selectRows = [];
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    delInfo(ids) {
      _this.isloading = true;
      _this.$api.course
        .removeById(ids)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    //子组件传值
    save() {
      _this.info.visible = false;
      _this.GetList();
    },
    liveDetail(id) {
      _this.liveInfo.visible = true;
      _this.liveInfo.id = id + "";
    },
    saveLiveInfo() {
      _this.liveInfo.visible = false;
    },
    saveNoticeInfo() {
      _this.noticeInfo.visible = false;
    },
    startLive(id) {
      _this.isloading = true;
      _this.$api.course
        .startLive(id)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    endLive(id) {
      _this.isloading = true;
      _this.$api.course
        .endLive(id)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.cang_sta {
  text-align: center;
}

.cang_sta img {
  width: 35px;
}
</style>

