<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="noticeForm"
        :rules="rules"
        :model="info"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="内容" prop="content">
          <a-textarea
            placeholder="请输入内容"
            :autosize="{ minRows: 4, maxRows: 6 }"
            v-model="info.content"
          />
        </a-form-model-item>
        <a-form-model-item label="发布时间" prop="publishTime">
          <a-date-picker
            show-time
            format="YYYY-MM-DD HH:mm:ss"
            @change="onChange"
            v-model="info.publishTime"
          />
        </a-form-model-item>
        <a-form-model-item label="状态" prop="status">
          <a-switch
            v-model="info.status"
            checked-children="启用"
            un-checked-children="禁用"
          />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 5 }">
          <pxkeji-btn name="确认" type="primary" @click="handleSubmit" />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
let _this;
import moment from "moment";
export default {
  name: "noticeInfo",
  props: {
    infos: {
      default: () => {},
    },
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 17 },
      info: {
        publishTime: "",
        status: true,
      },
      rules: {
        content: [
          { required: true, message: "请输入公告内容", trigger: "blur" },
        ],
      },
      isloading: false,
      loadingTip: "加载中...",
    };
  },
  mounted() {
    _this = this;
    if (_this.infos.id) {
      //编辑
      _this.getInfo();
    }
  },
  methods: {
    moment,
    momentTime(time, format) {
      return moment(time, format);
    },
    onChange(time, timeString){
      _this.info.publishTime=timeString;
    },
    getInfo() {
      _this.isloading = true;
      _this.$api.course
        .getLiveNoticeInfo(_this.infos.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.info = res.data;
            _this.info.status = _this.$commen.changeIntBoolean(res.data.status);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      _this.$refs.noticeForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          //修改
          let formData ={ ..._this.info };
          formData.courseId = _this.infos.courseId;
          formData.status = _this.$commen.changeBooleanInt(formData.status);
          console.log(_this.info)
          _this.$api.course
            .saveOrUpdateLiveNoticeInfo(formData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
</style>