<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="broadcastCourse"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="所属分类" prop="typeIds">
          <a-cascader
            placeholder="请选择"
            :options="treeData"
            changeOnSelect
            allowClear
            v-model="form.typeIds"
            :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
          />
        </a-form-model-item>
        <a-form-model-item label="课程名称" prop="name">
          <a-input
            placeholder="请输入"
            v-model="form.name"
            :maxLength="100"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="主讲教师" prop="name">
          <a-select v-model="form.teacherId">
            <a-select-option
              :value="info.id"
              v-for="(info, index) in teacherlist"
              :key="index"
              >{{ info.fullName }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="直播方式" prop="liveType">
          <a-select v-model="form.liveType">
            <a-select-option
              :value="info.type"
              v-for="(info, index) in liveTypeList"
              :key="index"
              >{{ info.name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="开始时间"
              prop="startTime"
              :labelCol="{ span: 8 }"
              :wrapperCol="{ span: 10 }"
            >
              <a-date-picker
                :showTime="{ format: 'HH:mm:ss' }"
                format="YYYY-MM-DD HH:mm:ss"
                v-model="form.startTime"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="结束时间"
              prop="endTime"
              :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 10 }"
            >
              <a-date-picker
                :showTime="{ format: 'HH:mm:ss' }"
                format="YYYY-MM-DD HH:mm:ss"
                v-model="form.endTime"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item
          label="封面图"
          prop="picUrl"
          help="注：建议图片尺寸270像素X165像素"
        >
          <a-upload
            accept="image/png,image/jpeg"
            listType="picture-card"
            :action="$api.file.uploadFile"
            :fileList="fileList"
            :beforeUpload="beforeUpload"
            @preview="handlePreview"
            @change="handleChange"
          >
            <div v-if="fileList.length == 0">
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="example" style="width: 100%" :src="form.picUrl" />
          </a-modal>
        </a-form-model-item>
        <a-form-model-item label="课程简介" prop="introduce">
          <a-textarea
            placeholder="请输入"
            v-model="form.introduce"
            :maxLength="500"
          />
        </a-form-model-item>
        <a-form-model-item label="pc详细介绍" prop="pcContent">
          <vue-ueditor-wrap
            v-model="form.pcContent"
            :config="myConfig"
          ></vue-ueditor-wrap>
        </a-form-model-item>
        <a-form-model-item label="app详细介绍" prop="appContent">
          <vue-ueditor-wrap
            v-model="form.appContent"
            :config="myConfig"
          ></vue-ueditor-wrap>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 19, offset: 4 }">
          <pxkeji-btn name="保存" @click="handleSubmit(0)" class="cancelBtn" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
import VueUeditorWrap from "vue-ueditor-wrap";

let _this;
export default {
  props: {
    id: String,
  },
  data() {
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { span: 17 },
      form: {
        typeId: null,
        typeIds:[],
        name: "",
        picUrl: [],
        liveType:"",
      },
      rules: {
        typeIds: [
          { required: true, message: "请选择所属分类", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        startTime: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        endTime: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
        picUrl: [
          { required: true, message: "请上传课程图片", trigger: "change" },
        ],
        introduce: [
          { required: true, message: "请输入课程简介", trigger: "change" },
        ],
        pcContent: [
          { required: true, message: "请输入课程详情", trigger: "change" },
        ],
        liveType: [
          { required: true, message: "请选择直播类型", trigger: "change" },
        ],
      },
      treeData: [],
      fileList: [],
      previewVisible: false,
      teacherlist: [],
      liveTypeList:[],
      myConfig: {
        autoHeightEnabled: false,
        initialFrameHeight: 180,
        wordCount: true,
        maximumWords: 100000,
        initialFrameWidth: "100%",
        serverUrl: this.$api.file.uploadUpFile,
        UEDITOR_HOME_URL: "/UEditor/",
        toolbars: this.$commen.editorConfig.toolbars,
      },
    };
  },
  mounted() {
    _this = this;
    _this.getTypeTree();
    _this.getTeacherlist(); //教师列表
    this.getLiveType(); //直播方式
    if (_this.id != "0") {
      //编辑
      _this.getInfo();
    }
  },
  components: {
    VueUeditorWrap,
  },
  methods: {
    /**取消 */
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    //分类
    async getTypeTree() {
      let res = await _this.$api.courseType.getUseTypeTree();
      if (res.errorCode == _this.$msg.responseCode.success) {
        _this.treeData = res.data;
      } else {
        _this.$message.error(res.errorMsg);
      }
    },
    getLiveType() {
      _this.$api.course
        .getLiveType()
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            res.data.forEach(function(item){
              if(item.type != 'aliyun'){
                _this.liveTypeList.push(item);
              }
            })
          } else {
            _this.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    //详情
    getInfo() {
      _this.isloading = true;
      _this.$api.course
        .getById(_this.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.form = {
              ...res.data,
              endTime: _this.$moment(res.data.endTime, "YYYY-MM-DD HH:mm:ss"),
              startTime: _this.$moment(
                res.data.startTime,
                "YYYY-MM-DD HH:mm:ss"
              ),
              typeIds: [res.data.typeId],
            };
            _this.getParentChain(res.data.typeId);
            if (res.data.picUrl) {
              _this.fileList.push({
                uid: 1,
                name: "课程图片1.png",
                status: "done",
                url: res.data.picUrl,
              });
            }
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    getParentChain(id) {
      _this.$api.courseType
        .getParentIdList(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.form.typeIds = res.data;
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    //获取教师列表
    getTeacherlist() {
      _this.isloading = true;
      _this.$api.teacher
        .getTeacherList()
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.teacherlist = res.data;
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      _this.$refs.broadcastCourse.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";

          if (_this.fileList.length < 1) {
            _this.$message.error("请上传封面图");
            _this.isloading = false;
            return false;
          }
          let formData = { ..._this.form };
          formData.typeId = formData.typeIds[formData.typeIds.length - 1];
          delete formData.typeIds;
          formData.classType = 1;
          formData.startTime = _this
            .$moment(formData.startTime)
            .format("YYYY-MM-DD HH:mm:ss");
          formData.endTime = _this
            .$moment(formData.endTime)
            .format("YYYY-MM-DD HH:mm:ss");
          formData.isGratis = 1;
          formData.originalPrice = 0;
          formData.presentPrice = 0;
          if (_this.id != "0") {
            // 编辑
            formData.id = _this.id;
          }
          _this.$api.course
            .saveOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    /**上传课程图片 */
    handleCancel() {
      _this.previewVisible = false;
    },
    handlePreview() {
      _this.previewVisible = true;
    },
    handleChange(info) {
      _this.fileList = info.fileList;
      if (info.file.status == "done") {
        if (info.file.response.errorCode == "0000") {
          _this.form.picUrl = info.file.response.data.path;
        } else {
          _this.$message.error("上传失败");
        }
      } else if (info.file.status == "error") {
        _this.$message.error("上传失败");
      } else if (info.file.status == "removed") {
        _this.form.picUrl = "";
      }
    },
    beforeUpload(file) {
      let isJPGPNG = false;
      if (file.type === "image/jpeg" || file.type === "image/png") {
        isJPGPNG = true;
      } else {
        isJPGPNG = false;
        _this.$message.error("只能上传jpg/png格式的文件!");
      }
      let isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        _this.$message.error("文件不能超过2MB!");
      }
      return isJPGPNG && isLt2M;
    },
  },
};
</script>
<style>
.zjitem {
  border: 1px solid #e8e8e8;
  padding-left: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}
.addzj,
.moveup,
.movedown {
  color: #1890ff;
}
</style>