<template>
  <div>
    <div class="pxkeji-right-box">
      <div>
        <pxkeji-btn
          name="添加"
          class="pxkeji_basic_btn"
          @click="showInfo({ courseId: id })"
          type="primary"
          :roles="[]"
        />
      </div>
    </div>
    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <span slot="status" slot-scope="status">
          <a-switch
            checkedChildren="启用"
            unCheckedChildren="禁用"
            :loading="switchLoading"
            v-model="status.status"
            @change="switchDisplay(status)"
          />
        </span>
        <span slot="action" slot-scope="action">
          <pxkeji-btn
            name="修改"
            @click="showInfo(action)"
            size="small"
            type="primary"
            :roles="[]"
          />
          <a-popconfirm @confirm="delInfo(action.id)">
            <div slot="title">是否确认删除此公告？</div>
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="删除"
              size="small"
              type="danger"
              :loading="isloading"
              :roles="[]"
            />
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <a-modal
      :title="addedit.title"
      v-model="addedit.visible"
      :footer="false"
      width="700px"
      destroyOnClose
      :maskClosable="false"
    >
      <LiveNoticeInfo :infos="addedit.info" @callbackMethod="save" />
    </a-modal>
  </div>
</template>
<script>
import LiveNoticeInfo from "./LiveNoticeInfo.vue";
const columns = [
  {
    dataIndex: "content",
    key: "content",
    align: "center",
    title: "内容",
    ellipsis: true,
  },
  {
    dataIndex: "publishTime",
    key: "publishTime",
    align: "center",
    title: "发布时间",
  },
  {
    key: "status",
    align: "center",
    title: "状态",
    width: "7%",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "liveNoticeList",
  props: {
    id: String,
  },
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 293,
      },
      //查询条件
      search: {
        name: "",
      },
      isloading: false,
      switchLoading: false, //开关等待
      addedit: {
        title: "",
        info: {},
        visible: false,
      },
    };
  },
  components: {
    LiveNoticeInfo,
  },
  mounted: function () {
    _this = this;
    console.log("id", _this.id);
    _this.GetList();
  },
  methods: {
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.$api.course
        .getLiveNoticeList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.id
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            res.data.records.forEach((value, index) => {
              res.data.records[index].status = this.$commen.changeIntBoolean(
                value.status
              );
            });
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    switchDisplay(e) {
      console.log("e", e);
      _this.switchLoading = true;
      _this.$api.course
        .updateLiveNoticeStatus(e.id, _this.$commen.changeBooleanInt(e.status))
        .then((res) => {
          _this.switchLoading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    showInfo(info) {
      //打开弹出框
      _this.addedit.visible = true;
      _this.addedit.title = info.id ? "编辑" : "新增";
      _this.addedit.info = info;
    },
    /**删除 */
    delInfo(id) {
      _this.isloading = true;
      _this.$api.course
        .removeLiveNoticeInfo(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    //子组件传值
    save() {
      _this.addedit.visible = false;
      _this.GetList();
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.items {
  /* margin: 15px 0; */
  border-bottom: 1px #e8e8e8 solid;
  padding: 20px 0;
  color: rgba(0, 0, 0, 0.85);
}
.selectStyle {
  width: 200px !important;
  display: inline-block;
  margin-left: 5px;
}
.rechargeIpt {
  width: 120px;
  margin: 0 20px 0 5px;
}
.costIpt {
  width: 180px;
  margin: 0 20px 0 5px;
}
.templateItem {
  float: left;
  margin-left: 10px;
  margin-right: 20px;
}
.temBtn {
  cursor: pointer;
  color: #1890ff;
}
.itemBtns .ant-btn-primary {
  margin: 0 5px;
}
</style>

